<template>
  <BidangUsahaForm mode="Tambah" module="Bidang Usaha"> </BidangUsahaForm>
</template>

<script>
import BidangUsahaForm from './form';

const BidangUsahaAdd = {
  name: 'BidangUsahaAdd',
  components: { BidangUsahaForm },
};

export default BidangUsahaAdd;
</script>
